import * as React from 'react';
import Layout from '../components/layout';
import ContactFormSection from '../components/sections/contact-form-section';

const ContactPage = () => {
  return (
    <Layout>
      <ContactFormSection />
    </Layout>
  );
};
export default ContactPage;
