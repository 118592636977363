import React from 'react';
import styled from 'styled-components';

import {ContainerLg} from '../components';

const StyledContactFormSection = styled.section.attrs({
  className: 'contact-form'
})`
  margin: 4rem 0;

  .container-lg {
    height: 100vh;
  }
`;

const ContactFormSection = () => (
  <StyledContactFormSection>
    <ContainerLg>
      <iframe
        width="100%"
        height="100%"
        src="https://us20.list-manage.com/contact-form?u=c5cab02c1c0a5dd0469cb98f1&form_id=42de0232a29fba5c3b752bdee6c4932b"
        title="Armada JS Contact Form"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </ContainerLg>
  </StyledContactFormSection>
);

export default ContactFormSection;
